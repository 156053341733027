<template>
  <div class="columnList">
    <div class="conent">
      <!-- 栏目页大图 -->
      <div class="lbt">
        <img src="../../assets/img/banner.jpg" />
      </div>
      <div class="tjlm_bg">
        <div class="tjlm">
          <ul>
            <li
              v-for="(item, index) in treelist"
              :key="index"
              :id="item.id"
              :class="cuIdx === index ? 'tjlm_on' : ''"
            >
              <template v-if="item.erOriginLink">
                <a
                  v-if="item.erOriginLink"
                  :href="item.erOriginLink"
                  target="_blank"
                >
                  {{ item.name }}</a
                >
              </template>
              <span
                v-else
                @click="reload('common', index, item)"
                :title="item.name"
                >{{ item.name }}</span
              >
            </li>
            <!-- <li
              v-for="(item, index) in tablist"
              :key="index"
              :id="item.id"
              :class="cuIdx === index.id ? 'tjlm_on' : ''"
            >
              <a v-if="item.url" :href="item.url" target="_blank">{{
                item.id
              }}</a>
              <span v-else :title="item.id">{{ item.id }}</span>
            </li> -->
          </ul>
        </div>
      </div>
      <!-- <listCommonVue
        v-if="currentTabComponent == 'common'"
        :treeData="curData"
        :pageTitle="curPageTitle"
        class="w1380 lmy"
      ></listCommonVue> -->
      <component
        ref="currentTabComponent"
        :is="currentTabComponent"
        class="w1380 lmy"
      ></component>
    </div>
  </div>
</template>

<script>
import jcxz from "./components/jcxz.vue";
import bssds from "./components/bssds.vue";
import zjjs from "./components/zjjs.vue";
import wjzj from "./components/wjzj.vue";
import qnyc from "./components/qnyc.vue";
// import listCommonVue from "./components/listCommon.vue";
export default {
  components: {
    // listCommonVue,
    jcxz,
    bssds,
    zjjs,
    wjzj,
    qnyc,
  },
  data() {
    return {
      cuIdx: 0,
      curPageTitle: "",
      curData: [],
      treelist: [],
      tablist: [
        {
          id: "杰出学者",
          components: "jcxz",
        },
        {
          id: "博士生导师",
          components: "bssds",
        },
        {
          id: "专家教授",
          components: "zjjs",
        },
        {
          id: "外籍专家",
          components: "wjzj",
        },
        {
          id: "青年英才",
          components: "qnyc",
        },
        {
          id: "师德师风",
          url: "http://shide.bisu.edu.cn",
        },
        {
          id: "教师发展",
          url: "http://cfd.bisu.edu.cn/",
        },
      ],
      currentTabComponent: "jcxz",
    };
  },
  created() {
    this.cuIdx = this.$route.query.id ? Number(this.$route.query.id) : 0;
    this.currentTabComponent = "jcxz";
    this.getTreeList();
  },
  methods: {
    async getTreeList() {
      const res = await this.API.basic.getColumn();
      if (res.data && res.data.length > 0) {
        let findColumn = res.data.findIndex((i) => {
          return i.uuid == this.$columnIdFile.szdw;
        });
        findColumn != "-1"
          ? (this.treelist = res.data[findColumn].children)
          : (this.treelist = []);
        this.treelist.forEach(async (i) => {
          if (
            i.uuid == this.$columnIdFile.szdwsdsf ||
            i.uuid == this.$columnIdFile.szdwjsfz
          ) {
            const params = {
              pageNumber: 0,
              pageSize: 1,
              columnIds: i.uuid,
            };
            const res = await this.API.basic.getHomeRotographList(params);
            if(res?.data?.content){
              i.erOriginLink = res.data?.content[0].mapProperties.erOriginLink;
            }
           
          } else {
            i.erOriginLink = null;
          }
          if (i.name === "师德师风") {
            i.erOriginLink = "http://shide.bisu.edu.cn";
          }
          if (i.name === "教师发展") {
            i.erOriginLink = "http://cfd.bisu.edu.cn/";
          }
          this.$forceUpdate();
        });
        this.$refs.currentTabComponent.content = this.treelist[this.cuIdx];
        // this.currentTabComponent = "common";
        // if (this.$route.query.id) {
        //   this.curPageTitle = this.treelist[this.cuIdx].columnName;
        //   this.curData = this.treelist[this.cuIdx].childList;
        // }
      } else {
        // this.cuIdx = this.$route.query.id ? Number(this.$route.query.id) : 0;
        // this.currentTabComponent = this.tablist[this.cuIdx].components;
      }
    },
    reload(component, index, data) {
      this.$refs.currentTabComponent.content = data;
      this.curPageTitle = data ? data.columnName : "";
      this.curData = data ? data.childList : [];
      this.cuIdx = index;
      // this.currentTabComponent = component;
    },
  },
};
</script>

<style lang="scss" scoped>
.columnList {
  padding-bottom: 50px;
  .conent .lbt {
    position: relative;
  }
  .conent .lbt img {
    width: 100%;
  }
}
.tjlm ul li.tjlm_on a {
  color: #fff;
}
</style>
