<template>
  <div class="jcxz">
    <el-dialog
      :title="previewData.title"
      :visible.sync="dialogVisible"
      width="60%"
    >
      <div>
        <template v-if="previewData.thumbnailInfo">
          <div v-if="previewData.thumbnailInfo.url" class="preview_data_img">
            <img :src="previewData.thumbnailInfo.url" alt="" />
          </div>
        </template>
        <div class="preview_data_html" v-html="previewData.content"></div>
      </div>
      <span slot="footer" class="dialog-footer"> </span>
    </el-dialog>
    <div class="page-herader">
      <span>{{ content.name }}</span>
    </div>
    <div class="lmy-center" v-if="content.children && content.children.length > 0">
      <div
        class="jcxz_item"
        v-for="(item, index) in content.children"
        :key="index"
      >
        <div class="teachers_lanmu">{{ item.name }}</div>
        <div class="teachers_name">
          <div
            class="teachers_lb"
            :style="{'width' : content.name === '外籍专家' ? '24%':'14%', 'cursor': (hasHtml(citem) ? 'pointer' : 'default')}"
            v-for="(citem, cindex) in item.contentData"
            :key="cindex"
            :title="citem.title"
            @click="preview(citem)"
          >
            {{ citem.title }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      previewData: "",
      dialogVisible: false,
      content: "",
      erOriginLinkShow: "",
      authorList: [
        { title: "教育部新世纪优秀人才", authors: ["林允清", "郑承军"] },
        {
          title: "享受国务院政府特贴",
          authors: [
            "王文炯",
            "王兴斌",
            "王文炯",
            "王兴斌",
            "王文炯",
            "王兴斌",
            "王文炯",
            "王兴斌",
            "王文炯",
            "王兴斌",
            "王文炯",
            "王兴斌",
            "王文炯",
            "王兴斌",
            "王文炯",
            "王兴斌",
          ],
        },
        { title: "国家级人才项目入选者", authors: ["林允清", "郑承军"] },
      ],
    };
  },
  watch: {
    content(val) {
      if (val) {
        this.getHomeRotographListFun();
      }
    },
  },
  mounted() {},
  methods: {
    preview(val) {
      // 创建DOMParser实例
      const parser = new DOMParser();

      // 解析HTML字符串为DOM对象
      const dom = parser.parseFromString(val.content, "text/html");

      // 遍历DOM的根节点的所有后代节点
      function hasTextContent(node) {
        if (
          node.nodeType === Node.TEXT_NODE &&
          node.textContent.trim() !== ""
        ) {
          return true;
        }
        for (let child of node.childNodes) {
          if (hasTextContent(child)) {
            return true;
          }
        }
        return false;
      }

      // 检查DOM中是否有文本内容
      const hasText = hasTextContent(dom.documentElement);
      if (!hasText) return;
      this.dialogVisible = true;
      this.previewData = val;
    },
    hasHtml(val) {
      // 创建DOMParser实例
      const parser = new DOMParser();

      // 解析HTML字符串为DOM对象
      const dom = parser.parseFromString(val.content, "text/html");

      // 遍历DOM的根节点的所有后代节点
      function hasTextContent(node) {
        if (
            node.nodeType === Node.TEXT_NODE &&
            node.textContent.trim() !== ""
        ) {
          return true;
        }
        for (let child of node.childNodes) {
          if (hasTextContent(child)) {
            return true;
          }
        }
        return false;
      }

      // 检查DOM中是否有文本内容
      const hasText = hasTextContent(dom.documentElement);
      return hasText;
    },
    getHomeRotographListFun() {
      if (this.content.children.length) {
        this.content.children.forEach(async (i) => {
          const params = {
            pageNumber: 0,
            pageSize: 100,
            columnIds: i.uuid,
          };
          const res = await this.API.basic.getHomeRotographList(params);
          i.contentData = res.data.content;
          this.$forceUpdate();
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.preview_data_img {
  display: flex;
  justify-content: center;
  img {
    height: 300px;
    max-width: 100% !important;
  }
}
.preview_data_html {
  ::v-deep img {
    max-width: 100% !important;
  }
}
.teachers_lanmu {
  padding: 10px 0 10px 25px;
  border-bottom: solid 1px #d6d6d6;
  line-height: 25px;
  font-size: 16px;
  font-family: "微软雅黑";
  background: url("../../../assets/img/jtIcon.png") 10px no-repeat;
  background-size: 10px 10px;
  color: #a73728;
}
.teachers_name {
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
  padding: 0 16px;
}
.teachers_lb {
  font-size: 16px;
  cursor: pointer;
  width: 14%;
  height: 54px;
  text-align: center;
  padding: 10px 0;
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}
</style>
