<template>
  <div class="jcxz">
    <div class="page-herader">
      <span>博士生导师</span>
    </div>
    <div class="lmy-center">
      <div class="jcxz_item" v-for="(item, index) in authorList" :key="index">
        <div class="teachers_lanmu">{{ item.title }}</div>
        <div class="teachers_name">
          <div
            class="teachers_lb"
            v-for="(citem, cindex) in item.authors"
            :key="cindex"
          >
            {{ citem }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      authorList: [
        { title: "教育部新世纪优秀人才", authors: ["林允清", "郑承军"] },
        {
          title: "享受国务院政府特贴",
          authors: [
            "王文炯",
            "王兴斌",
            "王文炯",
            "王兴斌",
            "王文炯",
            "王兴斌",
            "王文炯",
            "王兴斌",
            "王文炯",
            "王兴斌",
            "王文炯",
            "王兴斌",
            "王文炯",
            "王兴斌",
            "王文炯",
            "王兴斌",
          ],
        },
        { title: "国家级人才项目入选者", authors: ["林允清", "郑承军"] },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.teachers_lanmu {
  padding: 10px 0 10px 25px;
  border-bottom: solid 1px #d6d6d6;
  line-height: 25px;
  font-size: 16px;
  font-family: "微软雅黑";
  background: url("../../../assets/img/jtIcon.png") 10px no-repeat;
  background-size: 10px 10px;
  color: #a73728;
}
.teachers_name {
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
}
.teachers_lb {
  font-size: 16px;
  width: 14%;
  text-align: center;
  padding: 10px 0;
}
</style>
